import React from "react";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';
import { Container } from "styled-bootstrap-grid";

const SectionPrivacy = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_privacy_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledText>
          <Trans>service_terms_privacy_see_out_privacy</Trans>
          <P.StyledLink href={'https://mobd.pl/privacy'} target={'_blank'}>https://mobd.pl/privacy/</P.StyledLink>
          <Trans>service_terms_privacy_for_information</Trans>
        </P.StyledText>
      </Container>
    </P.StyledSection>
  )
}

export default SectionPrivacy;