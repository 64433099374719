import React from 'react';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionNoWarranties = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_no_warranties_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledText>
          <Trans>service_terms_no_warranties_disclaim_warranties</Trans>
        </P.StyledText>
      </Container>
    </P.StyledSection>
  )
}

export default SectionNoWarranties;