import React from "react";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';
import { Container } from "styled-bootstrap-grid";

export const SectionIntellectualProperty = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_intellectual_property_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledText>
          <Trans>service_terms_intellectual_property_copyright_laws</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_intellectual_property_give_you_license</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_intellectual_property_shall_not_copy_license</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_intellectual_property_exclusive_property</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_intellectual_property_content_generated</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_intellectual_property_should_be_construed</Trans>
        </P.StyledText>
      </Container>
    </P.StyledSection>
  )
}

export default SectionIntellectualProperty;