import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionNotices = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_notices_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledText>
          <Trans>service_terms_notices_any_notices</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_notices_by_registering_with_us</Trans>
        </P.StyledText>
      </Container>
    </P.StyledSection>
  )
}

export default SectionNotices;