import * as React from "react"
import Page from "../components/Page";
import { Copyright, Footer, Nav, Partners } from "../components/partials";
import SEO from "../components/utils/SEO";
import {graphql} from "gatsby";
import {useTranslation} from "react-i18next";
import {
  SectionAccountTerms,
  SectionCancellation, SectionChange,
  SectionDisputes, SectionEuUsers, SectionGeneral,
  SectionIndemnification,
  SectionIntellectualProperty,
  SectionLimitations, SectionNotices,
  SectionNoWarranties,
  SectionPayment,
  SectionPrivacy,
  SectionTermsOfService
} from "../components/termsOfService";

const DemoPage = () => {
  const {t} = useTranslation();

  return (
    <Page>
      <SEO title={t("page_title_terms_of_use")} />
      <Nav />
      <SectionTermsOfService />
      <SectionAccountTerms />
      <SectionPayment />
      <SectionCancellation />
      <SectionPrivacy />
      <SectionNoWarranties />
      <SectionLimitations />
      <SectionIntellectualProperty />
      <SectionIndemnification />
      <SectionDisputes />
      <SectionEuUsers />
      <SectionNotices />
      <SectionGeneral />
      <SectionChange />
      <Nav />
      <Footer />
      <Partners />
      <Copyright />
    </Page>
  );
};

export default DemoPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;