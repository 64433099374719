import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

export const SectionEuUsers = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_eu_users_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledText>
          <Trans>service_terms_eu_users_located_in_eu</Trans>
        </P.StyledText>
      </Container>
    </P.StyledSection>
  )
}

export default SectionEuUsers;