import React from 'react';
import Fade from "react-reveal";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionTermsOfService = () => {
  return (
    <P.StyledBigSection>
      <Fade>
        <Container>
          <P.StyledTitle>
            <Trans>service_terms_terms_of_service_title</Trans>
          </P.StyledTitle>
          <P.StyledUppercaseText>
            <Trans>service_terms_terms_of_service_binding_contract</Trans>
          </P.StyledUppercaseText>
          <P.StyledSubtitle revert={true}>
            <Trans>service_terms_terms_of_service_effective_date</Trans>
          </P.StyledSubtitle>
          <P.StyledText>
            <Trans>service_terms_terms_of_service_codahead_provide_services</Trans>
            <P.StyledLink href={'https://panel.mobd.pl/terms'} target={'_blank'}>https://panel.mobd.pl/terms</P.StyledLink>
            <Trans>service_terms_terms_of_service_any_new_features</Trans>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledBigSection>
  )
}

export default SectionTermsOfService;