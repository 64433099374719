import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionPayment = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_payment_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledList type={'decimal'}>
          <P.StyledListItem>
            <Trans>service_terms_payment_trial_period</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_account_will_be_renewed</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_non-refundable_fee</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_automatically_charge_credit_card</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_provide_must_provide_billing_information</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_right_to_charge_your_credit_card</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_reserve_the_right_to_change_prices</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_pay_all_taxes</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_payment_price_listed</Trans>
          </P.StyledListItem>
        </P.StyledList>
      </Container>
    </P.StyledSection>
  )
}

export default SectionPayment;