import styled from "styled-components";
import { Section } from "../partials";
import { GeneralTitle } from "../generalStyles";
import { animations, colors, fonts } from "../../config";

export const StyledSection = styled(Section)`
  padding: 20px 0;
  
  @media screen and (min-width: 576px) {
    padding: 30px 0;
  }
  
  @media screen and (min-width: 992px) {
    padding: 40px 0;
  }
`;

export const StyledBigSection = styled(Section)`
  padding: 120px 0 20px;
  
  @media screen and (min-width: 576px) {
    padding: 130px 0 30px;
  }

  @media screen and (min-width: 992px) {
    padding: 160px 0 40px;
  }
`;

export const StyledTitle = styled(GeneralTitle)`
  margin-bottom: 30px;
`;

export const StyledSubtitle = styled.h4`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  margin-bottom: ${({ revert }) => revert ? '0' : '90px'};
  margin-top: ${({ revert }) => revert ? '90px' : '0'};
  font-weight: 700;
  color: ${colors.white};

  @media screen and (max-width: 768px) {
    margin-bottom: ${({ revert }) => revert ? '0' : '40px'};
    margin-top: ${({ revert }) => revert ? '40px' : '0'};
  }
`;

export const StyledSectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

export const StyledText = styled.p`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  margin: 20px 0;
  line-height: 1.4;
`;

export const StyledLink = styled.a`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  text-decoration: underline;
  transition: ${animations.default};
  
  &:hover {
    color: ${colors.orange};
  }
`;

export const StyledList = styled.ul`
  list-style-type: ${({ type }) => type || 'disc'};
`;

export const StyledListItem = styled.li`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  line-height: 1.5;
  margin-left: 20px;
`;

export const StyledUppercaseText = styled(StyledText)`
  text-transform: uppercase;
`;

export const StyledBottomSubtitle = styled(StyledSubtitle)`
  margin: 20px 0;
  
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
`;