import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionAccountTerms = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_account_terms_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledList type={"decimal"}>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_13_years</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_parent_assist</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_be_a_human</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_provide_full_name</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_security_password</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_create_multiple_logins</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_must_not_use_service</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_may_not_attempt_to_modify</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_always_evolving</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_reserve_the_right_to_access</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_account_terms_may_not_following</Trans>
          </P.StyledListItem>
        </P.StyledList>
      </Container>
    </P.StyledSection>
  )
}

export default SectionAccountTerms;