import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionChange = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_change_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledText>
          <Trans>service_terms_change_trying_to_improve</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_change_do_not_agree_with_terms</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_change_expect_for_changes</Trans>
        </P.StyledText>
        <P.StyledBottomSubtitle>
          <Trans>service_terms_change_effective_date</Trans>
        </P.StyledBottomSubtitle>
      </Container>
    </P.StyledSection>
  )
}

export default SectionChange;