import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionDisputes = () => {
 return (
   <P.StyledSection>
     <Container>
       <P.StyledSectionTitle>
         <Trans>service_terms_disputes_title</Trans>
       </P.StyledSectionTitle>
       <P.StyledText>
         <Trans>service_terms_disputes_laws_of_the_poland</Trans>
       </P.StyledText>
       <P.StyledText>
         <Trans>service_terms_disputes_initial_payment</Trans>
       </P.StyledText>
     </Container>
   </P.StyledSection>
 )
}

export default SectionDisputes