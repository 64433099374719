import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionCancellation = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_cancellation_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledList type={'decimal'}>
          <P.StyledListItem>
            <Trans>service_terms_cancellation_solely_responsible</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_cancellation_immediately_deleted</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_cancellation_any_abuse</Trans>
          </P.StyledListItem>
          <P.StyledListItem>
            <Trans>service_terms_cancellation_all_provisions</Trans>
          </P.StyledListItem>
        </P.StyledList>
      </Container>
    </P.StyledSection>
  )
}

export default SectionCancellation;