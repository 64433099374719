import React from "react";
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import * as P from '../privacyPolicy/parts';

const SectionGeneral = () => {
  return (
    <P.StyledSection>
      <Container>
        <P.StyledSectionTitle>
          <Trans>service_terms_general_title</Trans>
        </P.StyledSectionTitle>
        <P.StyledText>
          <Trans>service_terms_general_determined</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_general_no_failure</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_general_the_section_titles</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_general_may_not_assign</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_general_claim_one_year</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_general_responsible_to_failure</Trans>
        </P.StyledText>
        <P.StyledText>
          <Trans>service_terms_general_agreement</Trans>
        </P.StyledText>
      </Container>
    </P.StyledSection>
  )
}

export default SectionGeneral;